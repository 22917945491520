import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
//
import MainLayout from 'src/layouts/main/layout';

// ----------------------------------------------------------------------

const HomePage = lazy(() => import('src/pages/home'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage title="PDF Form" />
        </MainLayout>
      ),
    },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
